.about-main {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 75px);
  /* padding: 1rem; */
}
.about-hero-img {
  max-height: 400px;
  height: 100%;
  border-radius: 25px;
  /* border: 5px solid var(--fg-color); */
}

.about-core {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}

.about-box {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.about-box h1,
.about-box-heading {
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* min-height: 75px; */
  align-self: center;
  /* flex-wrap: wrap; */
  font-size: 30px;
  background-color: var(--fg-color);
  padding: 1rem;
  color: var(--bg-color);
  font-family: var(--font-family-akira);
}
.about-box-heading h1 {
  width: 100%;
  flex-wrap: wrap;
  /* min-height: 75px; */
  align-self: center;
  font-size: 30px;
  padding: 0;
  font-family: var(--font-family-akira);
}
.about-box-heading p {
  border-radius: 15px;
  border: 3px solid white;

  width: 100%;
  /* min-height: 75px; */
  align-self: center;
  font-size: 25px;
  padding: 0.5rem 1rem;
  font-weight: 800;
  font-family: var(--font-family-space);
}
.about-box-cat-selected {
  background-color: white;

  color: black;
}
.about-box-project-category {
  display: flex;

  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.about-box-project-category p {
  cursor: pointer;
}
.about-box-project-category p:hover {
  background-color: white;
  color: black;
  /* border: 3px solid black; */
  transition: 0.5s;
}

.about-edu-item-logo {
  border-radius: 100%;
  /* width: 25%; */
  height: 150px;
}

.about-edu-data-container p {
  font-family: var(--font-family-montserrat);
  font-weight: 600;
}
.about-desc-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 2rem;

  gap: 3rem;
  /* min-height: calc(100vh - 75px); */
}
.about-skill-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  gap: 5rem;
  height: 100%;
}
#title {
  font-size: 30px;
  font-weight: 700;
}
.about-core {
  display: flex;
  justify-content: center;
  align-items: center;
}
#skill-title {
  border-radius: 25px 25px 0 0 !important;
  text-align: center;
}
#skill-content {
  padding: 2rem;
}
.about-skill-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: center; */
}
.about-skill-content p {
  font-size: 20px;
}
#skill-container {
  border: 2px solid white;
}
.about-hero-span {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* gap: 1rem; */
  /* border: 2px solid white; */
  /* padding: 1rem; */
  text-align: center;
  border-radius: 25px;
  min-height: 300px;
}
.about-hero-span h1 {
  text-align: center;
}
#skill-desc {
  color: slategray;
}
.about-skill-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.about-skill-list-container {
  width: 100%;
  padding: 2rem;
  display: flex;
  justify-content: space-around;
}
.about-skill-list-item {
  display: flex;
  gap: 1rem;
}
#home-projects-card {
  height: 300px;
}

.about-skill-marker {
  height: 25px;
}
.about-projects-container {
  display: flex;
  padding: 2rem;
  transition: 1s ease-in-out;
  /* height: calc(100vh - 75px); */
  justify-content: space-around;
  align-items: center;
  /* gap: 3rem; */
  /* flex-wrap: wrap; */
}
.about-skills-container {
  display: flex;
  padding: 2rem;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  /* min-height: calc(100vh - 75px); */
  gap: 5rem;
}
.about-projects-cards-row {
  display: flex;
  gap: 2rem;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
  /* background-color: red; */
}
#desc,
#duration {
  font-size: 18px;
}
#desc {
  font-weight: 500;
  font-size: 22px;
}
#aboutme {
  color: white;
  font-size: 22px;
  font-weight: 300;
  width: 100%;
}
#aboutme h2 {
  margin: 0;
}
#duration {
  color: gray;
}
.about-edu-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 2rem 3rem;
  height: 100%;
}
.events-vertical-card-bottom h1 {
  justify-content: center;
  color: white;
}

.about-edu-container:last-of-type hr {
  display: none;
}

.about-edu-main-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: flex-start;
}

.about-edu-data-container {
  display: flex;
  flex-direction: column;
}

.about-edu-result-container {
  display: flex;
  height: 100%;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
}

.continue-application {
  --color: #fff;
  --background: #404660;
  --background-hover: #3a4059;
  --background-left: #2b3044;
  --folder: #f3e9cb;
  --folder-inner: #beb393;
  --paper: #ffffff;
  --paper-lines: #bbc1e1;
  --paper-behind: #e1e6f9;
  --pencil-cap: #fff;
  --pencil-top: #275efe;
  --pencil-middle: #fff;
  --pencil-bottom: #5c86ff;
  --shadow: rgba(13, 15, 25, 0.2);
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  padding: 17px 29px 17px 69px;
  transition: background 0.3s;
  color: var(--color);
  background: var(--bg, var(--background));
}

.continue-application > div {
  top: 0;
  left: 0;
  bottom: 0;
  width: 53px;
  position: absolute;
  overflow: hidden;
  border-radius: 5px 0 0 5px;
  background: var(--background-left);
}

.continue-application > div .folder {
  width: 23px;
  height: 27px;
  position: absolute;
  left: 15px;
  top: 13px;
}

.continue-application > div .folder .top {
  left: 0;
  top: 0;
  z-index: 2;
  position: absolute;
  transform: translateX(var(--fx, 0));
  transition: transform 0.4s ease var(--fd, 0.3s);
}

.continue-application > div .folder .top svg {
  width: 24px;
  height: 27px;
  display: block;
  fill: var(--folder);
  transform-origin: 0 50%;
  transition: transform 0.3s ease var(--fds, 0.45s);
  transform: perspective(120px) rotateY(var(--fr, 0deg));
}

.continue-application > div .folder:before,
.continue-application > div .folder:after,
.continue-application > div .folder .paper {
  content: "";
  position: absolute;
  left: var(--l, 0);
  top: var(--t, 0);
  width: var(--w, 100%);
  height: var(--h, 100%);
  border-radius: 1px;
  background: var(--b, var(--folder-inner));
}

.continue-application > div .folder:before {
  box-shadow: 0 1.5px 3px var(--shadow), 0 2.5px 5px var(--shadow),
    0 3.5px 7px var(--shadow);
  transform: translateX(var(--fx, 0));
  transition: transform 0.4s ease var(--fd, 0.3s);
}

.continue-application > div .folder:after,
.continue-application > div .folder .paper {
  --l: 1px;
  --t: 1px;
  --w: 21px;
  --h: 25px;
  --b: var(--paper-behind);
}

.continue-application > div .folder:after {
  transform: translate(var(--pbx, 0), var(--pby, 0));
  transition: transform 0.4s ease var(--pbd, 0s);
}

.continue-application > div .folder .paper {
  z-index: 1;
  --b: var(--paper);
}

.continue-application > div .folder .paper:before,
.continue-application > div .folder .paper:after {
  content: "";
  width: var(--wp, 14px);
  height: 2px;
  border-radius: 1px;
  transform: scaleY(0.5);
  left: 3px;
  top: var(--tp, 3px);
  position: absolute;
  background: var(--paper-lines);
  box-shadow: 0 12px 0 0 var(--paper-lines), 0 24px 0 0 var(--paper-lines);
}

.continue-application > div .folder .paper:after {
  --tp: 6px;
  --wp: 10px;
}

.continue-application > div .pencil {
  height: 2px;
  width: 3px;
  border-radius: 1px 1px 0 0;
  top: 8px;
  left: 105%;
  position: absolute;
  z-index: 3;
  transform-origin: 50% 19px;
  background: var(--pencil-cap);
  transform: translateX(var(--pex, 0)) rotate(35deg);
  transition: transform 0.4s ease var(--pbd, 0s);
}

.continue-application > div .pencil:before,
.continue-application > div .pencil:after {
  content: "";
  position: absolute;
  display: block;
  background: var(
    --b,
    linear-gradient(
      var(--pencil-top) 55%,
      var(--pencil-middle) 55.1%,
      var(--pencil-middle) 60%,
      var(--pencil-bottom) 60.1%
    )
  );
  width: var(--w, 5px);
  height: var(--h, 20px);
  border-radius: var(--br, 2px 2px 0 0);
  top: var(--t, 2px);
  left: var(--l, -1px);
}

.continue-application > div .pencil:before {
  -webkit-clip-path: polygon(0 5%, 5px 5%, 5px 17px, 50% 20px, 0 17px);
  clip-path: polygon(0 5%, 5px 5%, 5px 17px, 50% 20px, 0 17px);
}

.continue-application > div .pencil:after {
  --b: none;
  --w: 3px;
  --h: 6px;
  --br: 0 2px 1px 0;
  --t: 3px;
  --l: 3px;
  border-top: 1px solid var(--pencil-top);
  border-right: 1px solid var(--pencil-top);
}

.continue-application:before,
.continue-application:after {
  content: "";
  position: absolute;
  width: 10px;
  height: 2px;
  border-radius: 1px;
  background: var(--color);
  transform-origin: 9px 1px;
  transform: translateX(var(--cx, 0)) scale(0.5) rotate(var(--r, -45deg));
  top: 26px;
  right: 16px;
  transition: transform 0.3s;
}

.continue-application:after {
  --r: 45deg;
}

.continue-application:hover {
  --cx: 2px;
  --bg: var(--background-hover);
  --fx: -40px;
  --fr: -60deg;
  --fd: 0.15s;
  --fds: 0s;
  --pbx: 3px;
  --pby: -3px;
  --pbd: 0.15s;
  --pex: -24px;
}

.animated-button {
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 16px 36px;
  border: 4px solid;
  border-color: transparent;
  font-size: 16px;
  background-color: inherit;
  border-radius: 100px;
  font-weight: 600;
  color: var(--fg-color);
  box-shadow: 0 0 0 2px var(--fg-color);
  cursor: pointer;
  overflow: hidden;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.animated-button svg {
  position: absolute;
  width: 24px;
  fill: var(--fg-color);
  z-index: 9;
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.animated-button .arr-1 {
  right: 16px;
}

.animated-button .arr-2 {
  left: -25%;
}

.animated-button .circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  background-color: var(--fg-color);
  border-radius: 50%;
  opacity: 0;
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.animated-button .text {
  position: relative;
  z-index: 1;
  transform: translateX(-12px);
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.animated-button:hover {
  box-shadow: 0 0 0 12px transparent;
  color: #212121;
  border-radius: 12px;
}

.animated-button:hover .arr-1 {
  right: -25%;
}

.animated-button:hover .arr-2 {
  left: 16px;
}

.animated-button:hover .text {
  transform: translateX(12px);
}

.animated-button:hover svg {
  fill: #212121;
}

.animated-button:active {
  scale: 0.95;
  box-shadow: 0 0 0 4px var(--fg-color);
}

.animated-button:hover .circle {
  width: 220px;
  height: 220px;
  opacity: 1;
}

@media (max-width: 1600px) {
  .about-projects-container {
    flex-wrap: wrap;
    gap: 3rem;
    padding: 1rem;
  }
}

@media (max-width: 1300px) {
  .about-hero-span {
    max-width: 500px;
  }
  .about-desc-container {
    flex-direction: column;
  }
  .about-desc-container h2 {
    text-align: center;
  }
  .about-hero-img {
    height: 300px;
  }
}

@media (max-width: 650px) {
  .about-box-heading {
    flex-direction: column;

    gap: 1rem;
  }
  .about-box-heading p {
    text-align: center;
    font-size: 20px;
  }
  .about-hero-img {
    height: 250px;
  }
}

@media (max-width: 500px) {
  .about-box-project-category {
    flex-direction: column;
  }
  #home-projects-card {
    height: fit-content;
  }
  .about-box-heading {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
  .about-box-heading h1 {
    text-align: center;
    display: inline;
  }
  .events-vertical-card-bottom h1,
  #skill-title {
    text-wrap: wrap;
    font-size: 25px;
  }
}
